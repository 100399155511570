import React from 'react'
import SEO from '../components/seo/seo'
import Disclaimer from '../components/disclaimer/disclaimer'
import Hero from '../components/hero/hero'


const ResourcesPage = () => (
  <>
    <SEO 
      title="Services" 
      description="The Law Office of James G. Feleen, PLLC located in Claremont, NH specializing in Real Estate Law, Estate Planning, Probabte and General Practice Law."
      keywords={['law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'probate administration', 'probate', 'personal injury', 'injury', 'wills', 'estate planning', 'title company', 'general practice', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <Hero />
    <div className="body-wrapper">
      <h1>Resources</h1>
      <div className="res-block">
        <h3>Useful Links</h3>
        <ul className='link-list'>
          <li><a href='http://www.claremontnh.com'>CLAREMONT, NH</a></li>
          <li><a href='http://www.sullivancountynh.gov/101/Departments'>SULLIVAN COUNTY CONTACTS</a></li>
          <li><a href='https://gcbor.net/'>GREATER CLAREMONT BOARD OF REALTORS</a></li>
          <li><a href='https://greaterclaremontnh.org/'>GREATER CLAREMONT CHAMBER OF COMMERCE</a></li>
          <li><a href='https://www.christianlegalsociety.org/'>CHRISTIAN LEGAL SOCIETY</a></li>
          <li><a href='https://www.etickernewsofclaremont.com/'>E-TICKER NEWS OF CLAREMONT</a></li>
          <li><a href='https://www.mascomabank.com/'>MASCOMA BANK</a></li>
          <li><a href='https://www.claremontsavings.com/'>CLAREMONT SAVINGS BANK</a></li>
          <li><a href='https://www.nhbar.org/'>NH BAR ASSOCIATION</a></li>
        </ul>
      </div>
      <div className="res-block">
        <h3>Working with a Lawyer</h3>
        <a href='https://www.nhbar.org/working-with-a-lawyer/'>NH BAR ASSOCIATION</a>
        <p>When do I need a lawyer, and how do I find one?</p>
      </div>
      <div className="res-block">
        <h3>Estate Planning Links</h3>
        <a href='http://www.healthynh.com/'>FOUNDATION FOR HEALTHY COMMUNITIES</a>
        <p>Includes Advance Care Planning Guide</p>
        <a href='http://www.neob.org/'>NEW ENGLAND DONOR SERVICES</a>
        <p>Includes information about organ and tissue donation.</p>
        <a href='http://www.nhbar.org/'>NEW HAMPSHIRE BAR ASSOCIATION</a>
        <p>Includes information specific to NH law on estate planning and advance directives, as well as finding a lawyer and other legal services.</p>
        <a href='https://www.nhla.org/'>NEW HAMPSHIRE LEGAL ASSISTANCE</a>
        <p>Information on Senior Citizens Law Project, providing legal service to elders.</p>
        <a href='https://www.courts.state.nh.us/probate/index.htm'>NEW HAMPSHIRE PROBATE COURT</a>
        <p>Contains probate court forms and instructions for completing those forms. Also includes information relative to guardianships, estates and other case types.</p>
        <a href='https://www.servicelink.nh.gov/'>SERVICE LINK</a>
        <p>Includes information to help individuals make informed decisions in care options for their loved ones. With locations throughout New Hampshire.</p>
        <a href='https://www.americanbar.org/groups/law_aging/'>AMERICAN BAR ASSOCIATION COMMISSION ON LAW AND AGING</a>
        <p>Includes information to help individuals make informed decisions in care options for their loved ones. With locations throughout New Hampshire.</p>
        <a href='https://www.medicare.gov/'>MEDICARE</a>
        <p>Includes ratings on Medicare certified providers.</p>
        <a href='https://www.guardianship.org/'>NATIONAL GUARDIANSHIP ASSOCIATION</a>
        <p>Includes many standards of ethics and practice, including standards for informed consent (standard #67), decision-making (standard #7) and decision-making about medical treatment (standard #14).</p>
        <a href='https://www.nhpco.org/'>NATIONAL HOSPICE AND PALLIATIVE CARE ORGANIZATION</a>
        <p>Includes information to help individuals make informed decisions in care options for their loved ones. With locations throughout New Hampshire.</p>
      </div>            
    </div>
    <Disclaimer />
  </>
)

export default ResourcesPage
