import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

import './heroLarge.css'

const Hero = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        <Img alt="The Brown Block office building on Pleasant Street in Claremont New Hampshire" className="hero-image" fluid={data.file.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "brown-block-office.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Hero;
